/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
// import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Cascader } from 'antd';

// import { AppState } from '../../reducers';
import CITY from '../../utils/city';
import COUNTRY from '../../utils/country';
import dataIndustry from '../../utils/industry';
import LANGUAGE_WORLD from '../../utils/languageType';
import PROVINCE from '../../utils/province';
import specialData from '../../utils/special';

const styles = (): StyleRules =>
  createStyles({
    content: {
      width: '80%',
      margin: '3% auto',
      padding: '1%',
    },

    selectWidth: {
      minWidth: '240px',
    },
    itemML: {
      minWidth: '80px',
      lineHeight: '42px',
    },
    linBOX: {
      height: '40px',
      lineHeight: '40px',
      fontWeight: 'bold',
      margin: '0 10px',
    },
    marg: {
      margin: '0 15px',
    },
    languageLabel: {
      border: '1px dashed #e4e4ee',
      padding: '0 10px',
      lineHeight: '40px',
      margin: '0 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textRight: {
      textAlign: 'right',
    },
  });

const ConsultantFilterDom = ({ classes, onChangefilter }: Props): ReactElement => {
  // const dispatch = useDispatch();
  const { SHOW_CHILD } = Cascader;
  const [addressState, setAddressState] = useState([]);
  const [industryArr, setIndustryArr] = useState([]);
  const [specialArr, setSpecialArr] = useState([]);

  const falseEducation = {
    MiddleSchool: false,
    VocationalSchool: false,
    Diploma: false,
    Bachelor: false,
    Master: false,
    Doctoral: false,
  };
  const trueEducation = {
    MiddleSchool: true,
    VocationalSchool: true,
    Diploma: true,
    Bachelor: true,
    Master: true,
    Doctoral: true,
  };

  const falseWorkMode = {
    remote: false,
    onsite: false,
  };

  const trueWorkYear = {
    work1to3: true,
    work3to5: true,
    work5to8: true,
    work8to15: true,
    workAbove15: true,
  };
  const falseWorkYear = {
    work1to3: false,
    work3to5: false,
    work5to8: false,
    work8to15: false,
    workAbove15: false,
  };

  const AreaOptions = COUNTRY.map(Item => {
    if (Item.name == '中国') {
      const provinceList = PROVINCE.map(item => {
        const cdata = CITY.map(citem => {
          if (item.code == citem.provinceCode) {
            return { label: citem.name, value: citem.code };
          }
        });
        const r = cdata.filter(s => {
          return s != undefined;
        });
        return { label: item.name, value: item.code, children: [...r] };
      });
      return { label: Item.name, value: Item.value, children: [...provinceList] };
    }
    return { label: Item.name, value: Item.value, children: [] };
  });

  const areaOnChange = (value: []): void => {
    if (value.length > 3) {
      setAddressState(value.slice(0, 3));
    } else {
      setAddressState([...value]);
    }
  };

  const industryOnChange = (value: []) => {
    if (value.length > 3) {
      setIndustryArr(value.slice(0, 3));
    } else {
      setIndustryArr([...value]);
    }
  };

  const specialOnChange = (value: []) => {
    if (value.length > 3) {
      setSpecialArr(value.slice(0, 3));
    } else {
      setSpecialArr([...value]);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'SelectAllWorkYear') {
      setSelectAllWorkYear(event.target.checked);
      if (event.target.checked) {
        setWorkingYear(trueWorkYear);
        // setConsultantWorkYearDisable(false);
        // setWorkYearValueStart('');
        // setWorYearValueEnd('');
      } else setWorkingYear(falseWorkYear);
    }
    if (event.target.name == 'selectAllEducational') {
      setSelectAllEducational(event.target.checked);
      if (event.target.checked) {
        setEducationState(trueEducation);
      } else setEducationState(falseEducation);
    }
  };

  // 工作经验
  const [selectAllWorkYear, setSelectAllWorkYear] = useState(false);
  const [workingYear, setWorkingYear] = useState(falseWorkYear);

  // const [workYearValueStart, setWorkYearValueStart] = useState<number | string>();
  // const [worYearValueEnd, setWorYearValueEnd] = useState<number | string>();
  // const [consultantWorkYearDisable, setConsultantWorkYearDisable] = useState(false);
  // const [workYearValueStartHelperText, setWorkYearValueStartHelperText] = useState<string>('');
  // const [workYearValueStartError, setWorkYearValueStartError] = useState<boolean>(false);
  // const [worYearValueEndHelperText, setWorYearValueEndHelperText] = useState<string>('');
  // const [worYearValueEndError, setWorYearValueEndError] = useState<boolean>(false);

  const workYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    // setWorkYearValueStart('');
    // setWorYearValueEnd('');
    setWorkingYear({ ...workingYear, [event.target.name]: event.target.checked });
  };

  // const workYearValueChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.name == 'starYear') {
  //     setWorkYearValueStartHelperText('');
  //     setWorkYearValueStartError(false);
  //     if (Number(event.target.value) < 0) {
  //       setWorkYearValueStartError(true);
  //       setWorkYearValueStartHelperText('不能小于0');
  //     } else if (Number(event.target.value) > 120) {
  //       setWorkYearValueStartError(true);
  //       setWorkYearValueStartHelperText('不能大于120');
  //     }
  //     setWorkYearValueStart(event.target.value);
  //   } else if (event.target.name == 'endYear') {
  //     setWorYearValueEndHelperText('');
  //     setWorYearValueEndError(false);
  //     if (Number(event.target.value) < 0) {
  //       setWorYearValueEndError(true);
  //       setWorYearValueEndHelperText('不能小于0');
  //     } else if (Number(event.target.value) > 120) {
  //       setWorYearValueEndError(true);
  //       setWorYearValueEndHelperText('不能大于120');
  //     }
  //     setWorYearValueEnd(event.target.value);
  //   }
  // };

  // const workYearButtonSubmit = () => {
  //   setWorkYearValueStartError(false);
  //   setWorkYearValueStartHelperText('');
  //   setWorYearValueEndError(false);
  //   setWorYearValueEndHelperText('');
  //   if (!workYearValueStart) {
  //     setWorkYearValueStartError(true);
  //     setWorkYearValueStartHelperText('最小工作经验不能为空');
  //   }
  //   if (!worYearValueEnd) {
  //     setWorYearValueEndError(true);
  //     setWorYearValueEndHelperText('最大工作经验不能为空');
  //     return;
  //   }
  //   if (Number(workYearValueStart) > Number(worYearValueEnd)) {
  //     setWorkYearValueStartError(true);
  //     setWorkYearValueStartHelperText('开始工作经验大于结束工作经验');
  //     return;
  //   }
  //   if (Number(workYearValueStart) < 0) {
  //     setWorkYearValueStartError(true);
  //     setWorkYearValueStartHelperText('不能小于0');
  //     return;
  //   } else if (Number(worYearValueEnd) > 120) {
  //     setWorYearValueEndError(true);
  //     setWorYearValueEndHelperText('不能大于120');
  //     return;
  //   }

  //   if (
  //     worYearValueEndError == false &&
  //     workYearValueStartError == false &&
  //     workYearValueStart &&
  //     worYearValueEnd
  //   ) {
  //     // onChangefilter({
  //     //   addressState,
  //     //   industryArr,
  //     //   specialArr,
  //     //   workingYear,
  //     //   reviewStatus,
  //     //   workStatus,
  //     //   ageStart,
  //     //   ageEnd,
  //     //   workScenario,
  //     //   educationState,
  //     //   workYearValueStart,
  //     //   worYearValueEnd,
  //     //   languageState,
  //     // });
  //   }
  // };

  // const resetWorYearValueButton = () => {
  //   setWorkYearValueStart('');
  //   setWorYearValueEnd('');
  //   setWorkYearValueStartError(false);
  //   setWorkYearValueStartHelperText('');
  //   setWorYearValueEndError(false);
  //   setWorYearValueEndHelperText('');
  // };

  // 学历
  const [selectAllEducational, setSelectAllEducational] = useState(false);
  const [educationState, setEducationState] = useState(falseEducation);

  const educationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEducationState({ ...educationState, [event.target.name]: event.target.checked });
  };

  // 工作方式
  const [workScenario, setWorkScenario] = useState(falseWorkMode);

  const workModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkScenario({ ...workScenario, [event.target.name]: event.target.checked });
  };

  // 年龄
  const [ageStart, setAgeStart] = useState<number | string>('');
  const [ageEnd, setAgeEnd] = useState<number | string>('');
  const [ageStartHelperText, setAgeStartHelperText] = useState<string>('');
  const [ageStartError, setAgeStartError] = useState<boolean>(false);
  const [ageEndHelperText, setAgeEndHelperText] = useState<string>('');
  const [ageEndError, setAgeEndError] = useState<boolean>(false);

  const ageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'ageStart') {
      setAgeStartError(false);
      setAgeStartHelperText('');

      if (Number(event.target.value) < 18) {
        setAgeStartError(true);
        setAgeStartHelperText('不能小于18岁');
      } else if (Number(event.target.value) > 120) {
        setAgeStartError(true);
        setAgeStartHelperText('不能大于120岁');
      }
      if (Number(event.target.value) == 0) {
        setAgeStart('');
      } else {
        setAgeStart(Number(event.target.value));
      }
    } else if (event.target.name == 'ageEnd') {
      setAgeEndError(false);
      setAgeEndHelperText('');

      if (Number(event.target.value) < 18) {
        setAgeEndError(true);
        setAgeEndHelperText('不能小于18岁');
      } else if (Number(event.target.value) > 120) {
        setAgeEndError(true);
        setAgeEndHelperText('不能大于120岁');
      }
      if (Number(event.target.value) == 0) {
        setAgeEnd('');
      } else {
        setAgeEnd(Number(event.target.value));
      }
    }
  };

  const ageButtonSubmit = () => {
    setAgeStartError(false);
    setAgeStartHelperText('');
    setAgeEndError(false);
    setAgeEndHelperText('');
    if (!ageStart) {
      setAgeStartError(true);
      setAgeStartHelperText('最小年龄不能为空');
    }
    if (!ageEnd) {
      setAgeEndError(true);
      setAgeEndHelperText('最大年龄不能为空');
      return;
    }
    if (ageStart! > ageEnd) {
      setAgeStartError(true);
      setAgeStartHelperText('开始年龄大于结束年龄');
      return;
    }
    if (!ageStartError && !ageEndError && ageStart && ageEnd) {
      onChangefilter({
        addressState,
        industryArr,
        specialArr,
        workingYear,
        reviewStatus,
        workStatus,
        ageStart,
        ageEnd,
        workScenario,
        educationState,
        languageState,
      });
    }
  };

  const ageButtonReset = () => {
    setAgeStart('');
    setAgeEnd('');
    setAgeStartError(false);
    setAgeStartHelperText('');
    setAgeEndError(false);
    setAgeEndHelperText('');
  };
  // 语言
  interface selectValue {
    value: string;
    label: string;
  }

  const [languageState, setLanguageState] = useState<selectValue[]>([]);

  const deleteLanguageStateItem = (e: selectValue) => {
    const newArray = languageState.filter(item => {
      return !JSON.stringify(item).includes(JSON.stringify(e));
    });
    setLanguageState(newArray);
  };

  // 工作状态
  const [workStatus, setWorkStatus] = useState('');

  const workStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkStatus(event.target.value);
  };

  // 审核状态
  const [reviewStatus, setReviewStatus] = useState('');

  const reviewStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReviewStatus(event.target.value);
  };

  const allButtonReset = () => {
    setAddressState([]);
    setIndustryArr([]);
    setSpecialArr([]);
    setWorkingYear(falseWorkYear);
    setEducationState(falseEducation);
    setWorkScenario(falseWorkMode);
    ageButtonReset();
    setLanguageState([]);
    setWorkStatus('');
    setReviewStatus('');
  };

  useEffect(() => {
    Object.values(workingYear).some(x => x === false)
      ? setSelectAllWorkYear(false)
      : setSelectAllWorkYear(true);
    Object.values(educationState).some(x => x === false)
      ? setSelectAllEducational(false)
      : setSelectAllEducational(true);

    onChangefilter({
      addressState,
      industryArr,
      specialArr,
      workingYear,
      reviewStatus,
      workStatus,
      ageStart,
      ageEnd,
      workScenario,
      educationState,
      languageState,
    });
  }, [
    addressState,
    industryArr,
    specialArr,
    workingYear,
    reviewStatus,
    workStatus,
    workScenario,
    educationState,
    languageState,
  ]);

  useEffect(() => {
    if (!ageStart && !ageEnd) {
      onChangefilter({
        addressState,
        industryArr,
        specialArr,
        workingYear,
        reviewStatus,
        workStatus,
        ageStart,
        ageEnd,
        workScenario,
        educationState,
        languageState,
      });
    }
  }, [ageStart, ageEnd]);
  return (
    <>
      <Card className={classes.content}>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              工作地点：
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Cascader
              className={classes.multiple}
              placeholder="请选择工作地点"
              options={AreaOptions}
              value={addressState}
              multiple
              onChange={areaOnChange}
              showCheckedStrategy={SHOW_CHILD}
              maxTagTextLength={3}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            行业：
          </Grid>

          <Grid item xs={10}>
            <Cascader
              className={classes.multiple}
              placeholder="请选择行业"
              options={dataIndustry}
              onChange={industryOnChange}
              value={industryArr}
              multiple
              showCheckedStrategy={SHOW_CHILD}
              maxTagTextLength={3}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            职能模块：
          </Grid>

          <Grid item xs={10}>
            <Cascader
              className={classes.multiple}
              placeholder="请选择职能"
              options={specialData}
              onChange={specialOnChange}
              value={specialArr}
              multiple
              showCheckedStrategy={SHOW_CHILD}
              maxTagTextLength={3}
              style={{
                width: 200,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              工作经验：
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllWorkYear}
                    onChange={handleChange}
                    name="SelectAllWorkYear"
                    color="primary"
                  />
                }
                label="全部"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workingYear.work1to3}
                    onChange={workYearChange}
                    name="work1to3"
                    color="primary"
                    value="1-3"
                  />
                }
                label="初级助理（1-3年）"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workingYear.work3to5}
                    onChange={workYearChange}
                    name="work3to5"
                    color="primary"
                  />
                }
                label="专员及助理经理（3-5年）"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workingYear.work5to8}
                    onChange={workYearChange}
                    name="work5to8"
                    color="primary"
                  />
                }
                label="顾问及经理（5-8年）"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workingYear.work8to15}
                    onChange={workYearChange}
                    name="work8to15"
                    color="primary"
                  />
                }
                label="资深顾问及资深经理（8-15年）"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workingYear.workAbove15}
                    onChange={workYearChange}
                    name="workAbove15"
                    color="primary"
                  />
                }
                label="执行顾问及总监（15年+）"
              />
              {/* <FormControlLabel
                control={
                  <Button
                    onClick={() => {
                      setConsultantWorkYearDisable(true);
                      setWorkingYear(falseWorkYear);
                      setSelectAllWorkYear(false);
                    }}
                    color="primary"
                  >
                    自定义
                  </Button>
                }
                label=""
              /> */}
            </Grid>
          </Grid>
        </Grid>
        {/* {consultantWorkYearDisable && (
          <Grid container item xs={12}>
            <Grid item xs={1} className={classes.itemML}></Grid>
            <Grid container item xs={11}>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                value={workYearValueStart}
                helperText={workYearValueStartHelperText}
                error={workYearValueStartError}
                inputProps={{
                  min: 1,
                  max: 120,
                  step: 1,
                }}
                type="number"
                name="starYear"
                onChange={workYearValueChange}
              />
              <div className={classes.linBOX}>—</div>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                value={worYearValueEnd}
                helperText={worYearValueEndHelperText}
                error={worYearValueEndError}
                inputProps={{
                  min: 1,
                  max: 120,
                  step: 1,
                }}
                type="number"
                name="endYear"
                onChange={workYearValueChange}
              />
              <div className={classes.linBOX}>年</div>
              <Button
                size="small"
                variant="contained"
                className={classes.linBOX}
                onClick={workYearButtonSubmit}
                color="primary"
              >
                确认
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.linBOX}
                onClick={resetWorYearValueButton}
              >
                重置
              </Button>
              <IconButton
                className={classes.linBOX}
                size="small"
                aria-label="delete"
                onClick={() => {
                  setConsultantWorkYearDisable(false);
                  setWorkYearValueStart('');
                  setWorYearValueEnd('');
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        )} */}
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              学历要求：
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllEducational}
                    onChange={handleChange}
                    name="selectAllEducational"
                    color="primary"
                  />
                }
                label="全部"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.MiddleSchool}
                    onChange={educationChange}
                    name="MiddleSchool"
                    color="primary"
                  />
                }
                label="初中及以下"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.VocationalSchool}
                    onChange={educationChange}
                    name="VocationalSchool"
                    color="primary"
                  />
                }
                label="高中/中专"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.Diploma}
                    onChange={educationChange}
                    name="Diploma"
                    color="primary"
                  />
                }
                label="大专"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.Bachelor}
                    onChange={educationChange}
                    name="Bachelor"
                    color="primary"
                  />
                }
                label="本科"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.Master}
                    onChange={educationChange}
                    name="Master"
                    color="primary"
                  />
                }
                label="硕士"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.Doctoral}
                    onChange={educationChange}
                    name="Doctoral"
                    color="primary"
                  />
                }
                label="博士"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.xl7}
                    onChange={educationChange}
                    name="xl7"
                    color="primary"
                  />
                }
                label="无学历要求"
              /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              语言：
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container item xs={12}>
              <Autocomplete
                size="small"
                className={classes.selectWidth}
                // defaultValue={null}
                id="combo-box-demo"
                options={LANGUAGE_WORLD}
                getOptionLabel={option => option.label}
                renderInput={params => {
                  return <TextField {...params} label="选择语言" variant="outlined" />;
                }}
                // value={
                //   (i18n.language == 'enUS' ? CertificateTypeArrayEn : CertificateTypeArray).find(
                //     option => option.value === field.value
                //   ) || null
                // }
                onChange={(_, newValue) => {
                  if (newValue) {
                    setLanguageState([...new Set([newValue, ...languageState])]);
                  }
                }}
              />
              {languageState.map((e, index) => {
                return (
                  <div key={index} className={classes.languageLabel}>
                    {e.label} <DeleteForeverIcon onClick={() => deleteLanguageStateItem(e)} />
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              工作方式：
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workScenario.remote}
                    onChange={workModeChange}
                    name="remote"
                    color="primary"
                  />
                }
                label="线上"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={workScenario.onsite}
                    onChange={workModeChange}
                    name="onsite"
                    color="primary"
                  />
                }
                label="线下"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              年龄：
            </Grid>
          </Grid>
          <Grid container item xs={11}>
            <Grid container item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                onChange={ageInputChange}
                helperText={ageStartHelperText}
                error={ageStartError}
                value={ageStart}
                variant="outlined"
                type="number"
                name="ageStart"
              />
              <div className={classes.linBOX}>—</div>
              <TextField
                id="outlined-basic"
                size="small"
                onChange={ageInputChange}
                helperText={ageEndHelperText}
                error={ageEndError}
                value={ageEnd}
                variant="outlined"
                type="number"
                name="ageEnd"
              />
              <div className={classes.linBOX}>岁</div>

              <Button
                size="small"
                variant="contained"
                className={classes.linBOX}
                onClick={ageButtonSubmit}
                color="primary"
              >
                确认
              </Button>

              <Button
                size="small"
                variant="contained"
                className={classes.linBOX}
                onClick={ageButtonReset}
              >
                重置
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              工作状态：
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={workStatus === 'all'}
                    onChange={workStatusChange}
                    value="all"
                    name="radio-button-demo"
                  />
                }
                label="全部"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={workStatus === 'FindingJob'}
                    onChange={workStatusChange}
                    value="FindingJob"
                    name="radio-button-demo"
                  />
                }
                label="接单"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={workStatus === 'notFindingJob'}
                    onChange={workStatusChange}
                    value="notFindingJob"
                    name="radio-button-demo"
                  />
                }
                label="不接单"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} className={classes.itemML}>
            <Grid container item xs={12}>
              审核状态：
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={reviewStatus === 'c'}
                    onChange={reviewStatusChange}
                    value="c"
                    name="radio-button-demo"
                  />
                }
                label="全部"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={reviewStatus === 'VERIFIED'}
                    onChange={reviewStatusChange}
                    value="VERIFIED"
                    name="radio-button-demo"
                  />
                }
                label="已审核"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    checked={reviewStatus === 'b'}
                    onChange={reviewStatusChange}
                    value="b"
                    name="radio-button-demo"
                  />
                }
                label="未审核"
              />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.textRight}>
          <Button size="small" variant="contained" onClick={allButtonReset}>
            全部重置
          </Button>
        </div>
      </Card>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onChangefilter: Function;
}

export default withStyles(styles)(ConsultantFilterDom);

import React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { createStyles, StyleRules, Theme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Layout from '../../../layout/AdminLayout';
import ConsultantListView from '../../../views/admin/ConsultantListView';
import ConsultantFilterDom from '../../../views/consultant/ConsultantFilterView';
const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      width: 1128,
      margin: 'auto',
      height: theme.spacing(3),
    },
  });

const Consultant = (): React.ReactElement => {
  const [filters, setFilters] = useState<FilterDateType>();
  const handleFilterChange = (newFilters: FilterDateType) => {
    setFilters(newFilters);
  };

  return (
    <Layout>
      {/* Header */}
      <Helmet title="顾问管理"></Helmet>
      <ConsultantFilterDom onChangefilter={handleFilterChange} />
      <ConsultantListView filtersData={filters} />
    </Layout>
  );
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
}

export default withStyles(styles)(Consultant);

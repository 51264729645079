/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Button, Chip, Theme } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/styles';
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { navigate } from 'gatsby';

import { printConsultantDetailList } from '../../actions/admin/adminActions';
import { adminDeleteConsultantList } from '../../actions/admin/adminActions';
import { ConsultantStatusEnum } from '../../reducers/consultantReducer';
import { loadingEndAction, loadingStartAction } from '../../reducers/loadingReducer';
import { showNotificationAction } from '../../reducers/notificationReducer';
import CITY from '../../utils/city';
import { computeAgeDay, computeWorkYear, dateFormatYearMonthDay } from '../../utils/converter';
import debug from '../../utils/debug';
import PROVINCE from '../../utils/province';
const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      maxWidth: '80%',
      height: 900,
      margin: 'auto',
      paddingBottom: '5%',
      '& .grid-data-cell-NONE': {
        backgroundColor: theme.palette.grey,
        '&:hover': {
          backgroundColor: theme.palette.grey[500],
        },
      },
      '& .grid-data-cell-SUBMITTED': {
        backgroundColor: theme.palette.info.main,
        '&:hover': {
          backgroundColor: theme.palette.info.dark,
        },
      },
      '& .grid-data-cell-VERIFIED': {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },
      },
      '& .grid-data-cell-DENIED': {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
    DownloadPDFsDiv: {
      textAlign: 'right',
    },
    DownloadPDFs: {
      position: 'relative',
      margin: '20px',
    },
  });

const GET_ALL_CONSULTANTS = gql`
  query QUERY {
    adminGetAllConsultantVerification {
      id
      status
      consultant {
        id
      }
      createTime
      basicInfo {
        id
        firstName
        lastName
        contactEmail
        gender
        nationality
        regionProvince
        regionCity
        nowAddressProvince
        nowAddressCity
        phoneNumber
        birthday
        passportType
        passportId
        nowAddressDetail
        personalDescription
        nowAddressDistrict
      }
      educationInfoList {
        id
        education
        schoolName
        majorType
        academicDirections
      }
      workInfoList {
        id
        companyName
        wayOfWorking
        jobTitle
        industryClass
        professionalClass
        atWorkDateRangeStart
        atWorkDateRangeEnd
        jobDescription
      }
      languageInfoList {
        id
        languageLevel
        languageName
      }
      settings {
        findingJob
        id
        workDossier
        workType
        workScenario
        expectedFeePerHour
        expectedFeePerMonth
      }
    }
  }
`;

const ConsultantListView = ({ classes, filtersData }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<GridRowData[]>([]);
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set([]));
  const [newTableData, setNewTableData] = useState<GridRowData[]>([]);
  const handleClickOpen = (data: GridRowParams): void => {
    dispatch(loadingStartAction());
    if (!data) return;
    navigate('/admin/consultant/detail' + '#' + data.row.cid, { state: { row: data.row.cid } });
  };
  const { data, loading, error } = useQuery(GET_ALL_CONSULTANTS);

  if (loading) {
    dispatch(loadingStartAction());
  } else {
    dispatch(loadingEndAction());
  }

  useEffect(() => {
    if (error) {
      dispatch(showNotificationAction({ severity: 'error', message: error.message }));
    }
  }, [error]);

  const columns: GridColDef[] = [
    {
      headerName: '名称',
      field: 'lastName',
      width: 250,
      // editable: true,
    },
    {
      headerName: '邮箱',
      field: 'contactEmail',
      width: 350,
      // editable: true,
    },
    {
      headerName: '性别',
      field: 'gender',
      width: 200,
      // editable: true,
    },
    {
      headerName: '国籍',
      field: 'nationality',
      width: 200,
      // editable: true,
    },
    {
      headerName: '地区(省/市)',
      field: 'nowAddressLocation',
      width: 200,
      // editable: true,
    },
    {
      headerName: '注册时间',
      field: 'createTime',
      width: 200,
      // editable: true,
    },
    {
      headerName: '审核状态',
      field: 'displayedStatus',
      width: 150,
      // editable: false,
      renderCell: params => {
        return (
          <Chip
            label={params.formattedValue}
            className={`grid-data-cell-${params.row.status || 'NONE'}`}
          />
        );
      },
    },
  ];

  const onSelectionModelChange = (model: GridSelectionModel) => {
    const ids = new Set(model);
    setSelectedIds(ids as Set<number>);
  };

  useEffect(() => {
    if (error) {
      dispatch(showNotificationAction({ severity: 'error', message: '获取所有公司认证信息失败' }));
    }

    if (data && data.adminGetAllConsultantVerification) {
      const verificationList = data.adminGetAllConsultantVerification;
      debug(verificationList);
      setTableData(
        verificationList
          .map((v: ConsultantVerification) => {
            if (!v || !v.basicInfo || !v.status) return;
            return {
              id: v.id,
              cid: v?.consultant?.id,
              lastName: v.basicInfo.firstName + v.basicInfo.lastName,
              contactEmail: v.basicInfo.contactEmail,
              gender: v.basicInfo.gender == 'male' ? '男' : '女',
              nationality: v.basicInfo.nationality,
              regionLocation: v.basicInfo.regionProvince + ', ' + v.basicInfo.regionCity,
              nowAddressLocation:
                `${PROVINCE.find(c => c.code == v?.basicInfo?.nowAddressProvince)?.name} ` +
                `${CITY.find(c => c.code == v?.basicInfo?.nowAddressCity)?.name} `,
              nowAddressCityCode: v.basicInfo.nowAddressCity,
              nowAddressProvinceCode: v.basicInfo.nowAddressProvince,
              phoneNumber: v.basicInfo.phoneNumber,
              birthday: computeAgeDay(v.basicInfo.birthday),
              createTime: v.createTime ? dateFormatYearMonthDay(v.createTime) : '',
              passportType: v.basicInfo.passportType,
              passportId: v.basicInfo.passportId,
              personalDescriptionFileList: v.basicInfo.personalDescriptionFileList,
              // workStartDate: v.basicInfo.workStartDate,
              nowAddressDetail: v.basicInfo.nowAddressDetail,
              personalDescription: v.basicInfo.personalDescription,
              status: v.status,
              displayedStatus: ConsultantStatusEnum[v.status]
                ? ConsultantStatusEnum[v.status]
                : 'NONE',
              educationInfoList: v.educationInfoList,
              settings: v.settings,
              languageInfoList: v.languageInfoList,
              workInfoList: v.workInfoList,
            };
          })
          .filter((item: ConsultantVerification) => item !== undefined)
      );
    }
  }, [data]);

  useEffect(() => {
    setNewTableData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (filtersData && Object.keys(filtersData).length > 0) {
      const hasTrueAllWorkYear = Object.values(filtersData.workingYear).some(x => x === true);
      const hasTrueAllWorkYearFalse = Object.values(filtersData.workingYear).some(x => x === false);
      const hasTrueAllEducational = Object.values(filtersData.educationState).some(x => x === true);

      const dataList = [...tableData].filter(el => {
        const isArr: boolean[] = [];

        if (filtersData.addressState.length > 0) {
          const isCityCodeArr: boolean[] = [];
          filtersData.addressState.forEach(element => {
            element[2] == el.nowAddressCityCode
              ? isCityCodeArr.push(true)
              : isCityCodeArr.push(false);
          });
          isCityCodeArr.some(x => x == true) ? isArr.push(true) : isArr.push(false);
        }

        if (filtersData.industryArr.length > 0) {
          let industryArr: [][] = [];
          el.workInfoList.forEach((element: { industryClass: string }) => {
            if (element.industryClass) {
              const industryClass = JSON.parse(element.industryClass);
              industryArr = [industryArr, ...industryClass];
              industryArr.shift();
            }
          });
          if (industryArr.length > 0) {
            const isIndustryArr: boolean[] = [];
            const newest = new Set(industryArr);
            filtersData.industryArr.forEach(element => {
              newest.forEach(industryArrItem => {
                element[2] == industryArrItem[2]
                  ? isIndustryArr.push(true)
                  : isIndustryArr.push(false);
              });
            });

            isIndustryArr.some(x => x === true) ? isArr.push(true) : isArr.push(false);
          } else {
            isArr.push(false);
          }
        }

        if (filtersData.specialArr.length > 0) {
          let specialArr: [][] = [];
          el.workInfoList.forEach((element: { professionalClass: string }) => {
            if (element.professionalClass) {
              const professionalClass = JSON.parse(element.professionalClass);
              specialArr = [specialArr, ...professionalClass];
              specialArr.shift();
            }
          });
          if (specialArr.length > 0) {
            const isSpecialArr: boolean[] = [];
            const newest = new Set(specialArr);
            filtersData.specialArr.forEach(element => {
              newest.forEach(industryArrItem => {
                element[1] == industryArrItem[1]
                  ? isSpecialArr.push(true)
                  : isSpecialArr.push(false);
              });
            });

            isSpecialArr.some(x => x === true) ? isArr.push(true) : isArr.push(false);
          } else {
            isArr.push(false);
          }
        }

        if (hasTrueAllEducational) {
          let educationStateString = '';
          Object.keys(filtersData.educationState).forEach(educationStateItem => {
            if (filtersData.educationState[educationStateItem] == true) {
              educationStateString += educationStateItem;
            }
          });
          const EducationInfoIsArray: boolean[] = [];
          el.educationInfoList.forEach((element: ConsultantEducationInfo) => {
            if (element.education && educationStateString.includes(element.education)) {
              EducationInfoIsArray.push(true);
            } else {
              EducationInfoIsArray.push(false);
            }
          });
          EducationInfoIsArray.some(x => x == true) ? isArr.push(true) : isArr.push(false);
        } else {
          isArr.push(true);
        }

        if (hasTrueAllWorkYear) {
          if (!hasTrueAllWorkYearFalse) {
            isArr.push(true);
          } else {
            const dateArray: string[] = [];
            el.workInfoList.forEach(
              (element: { atWorkDateRangeEnd: string; atWorkDateRangeStart: string }) => {
                element.atWorkDateRangeStart ? dateArray.push(element.atWorkDateRangeStart) : '';
                element.atWorkDateRangeEnd ? dateArray.push(element.atWorkDateRangeEnd) : '';
              }
            );
            if (dateArray.length > 0) {
              dateArray.sort((a, b) => {
                return new Date(b) - new Date(a);
              });
              const nowWorkYear = computeWorkYear(dateArray[dateArray.length - 1], dateArray[0]);
              const isWorkingYear: boolean[] = [];
              if (filtersData.workingYear?.work1to3) {
                if (nowWorkYear >= 1 && nowWorkYear <= 3) {
                  isWorkingYear.push(true);
                } else {
                  isWorkingYear.push(false);
                }
              }
              if (filtersData.workingYear?.work3to5) {
                if (nowWorkYear >= 3 && nowWorkYear <= 5) {
                  isWorkingYear.push(true);
                } else {
                  isWorkingYear.push(false);
                }
              }
              if (filtersData.workingYear?.work5to8) {
                if (nowWorkYear >= 5 && nowWorkYear <= 8) {
                  isWorkingYear.push(true);
                } else {
                  isWorkingYear.push(false);
                }
              }
              if (filtersData.workingYear?.work8to15) {
                if (nowWorkYear >= 8 && nowWorkYear <= 15) {
                  isWorkingYear.push(true);
                } else {
                  isWorkingYear.push(false);
                }
              }
              if (filtersData.workingYear?.workAbove15) {
                if (nowWorkYear >= 15) {
                  isWorkingYear.push(true);
                } else {
                  isWorkingYear.push(false);
                }
              }
              isWorkingYear.some(x => x == true) ? isArr.push(true) : isArr.push(false);
            } else {
              isArr.push(false);
            }
          }
        } else {
          isArr.push(true);
        }

        if (filtersData.languageState.length > 0) {
          if (el.languageInfoList.length > 0) {
            let educationStateString = '';
            filtersData.languageState.forEach(Item => {
              educationStateString += Item.value;
            });
            const languageInfoListIsArray: boolean[] = [];
            el.languageInfoList.forEach((element: ConsultantLanguageInfo) => {
              languageInfoListIsArray.push(educationStateString.includes(element.languageName));
            });
            languageInfoListIsArray.some(x => x == true) ? isArr.push(true) : isArr.push(false);
          } else {
            isArr.push(false);
          }
        }

        if (filtersData.workScenario.onsite == false && filtersData.workScenario.remote == false) {
          isArr.push(true);
        } else if (filtersData.workScenario.onsite && filtersData.workScenario.remote) {
          isArr.push(true);
        } else {
          if (el.settings?.workScenario) {
            const workScenario = JSON.parse(el.settings?.workScenario);

            if (filtersData.workScenario.onsite && !filtersData.workScenario.remote) {
              workScenario.onsite ? isArr.push(true) : isArr.push(false);
            }
            if (!filtersData.workScenario.onsite && filtersData.workScenario.remote) {
              workScenario.remote ? isArr.push(true) : isArr.push(false);
            }
          } else {
            isArr.push(false);
          }
        }

        if (filtersData.ageStart && filtersData.ageEnd) {
          if (el.birthday >= filtersData.ageStart && el.birthday <= filtersData.ageEnd) {
            isArr.push(true);
          } else {
            isArr.push(false);
          }
        } else {
          isArr.push(true);
        }

        if (filtersData.workStatus == 'all' || !filtersData.workStatus) {
          isArr.push(true);
        } else {
          if (filtersData.workStatus && el.settings?.findingJob) {
            filtersData.workStatus == el.settings.findingJob ? isArr.push(true) : isArr.push(false);
          } else {
            isArr.push(false);
          }
        }

        if (filtersData.reviewStatus == 'c' || !filtersData.reviewStatus) {
          isArr.push(true);
        } else if (filtersData.reviewStatus == 'b') {
          el.status !== 'VERIFIED' ? isArr.push(true) : isArr.push(false);
        } else {
          isArr.push(el.status.includes('VERIFIED'));
        }

        return isArr.includes(false) ? false : true;
      });

      setNewTableData(dataList);
    }
  }, [filtersData]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.DownloadPDFsDiv}>
          <Button
            className={classes.DownloadPDFs}
            onClick={() => {
              if (Array.from(selectedIds).length <= 0) {
                dispatch(showNotificationAction({ severity: 'warning', message: '未选择顾问' }));
                return;
              }
              dispatch(printConsultantDetailList(Array.from(selectedIds)));
            }}
            variant="contained"
            color="primary"
            style={{
              width: '20%',
            }}
          >
            批量下载简历
          </Button>{' '}
          <Button
            className={classes.DownloadPDFs}
            onClick={() => {
              if (Array.from(selectedIds).length <= 0) {
                dispatch(showNotificationAction({ severity: 'warning', message: '未选择顾问' }));
                return;
              }
              dispatch(adminDeleteConsultantList(Array.from(selectedIds)));
            }}
            variant="contained"
            color="primary"
            style={{
              width: '20%',
            }}
          >
            删除选中顾问
          </Button>
        </div>
        <DataGrid
          // disableSelectionOnClick={true}
          // rows={filterData}
          rows={newTableData}
          columns={columns}
          rowHeight={40}
          disableSelectionOnClick={true}
          onRowClick={handleClickOpen}
          onSelectionModelChange={onSelectionModelChange}
          checkboxSelection
        />
      </div>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  filtersData?: FilterDateType;
}

export default withStyles(styles)(ConsultantListView);
